import React from 'react'
import PhotoCards from './PhotoCards';

function Gallery() {
  return (
    <div>
      {/* Gallery */}
      <PhotoCards />
    </div>
  )
}

export default Gallery
